jQuery(document).ready(function($) {
	
	var captcha_value;
	
	$('.call-1popup').on('click', function ( e ) {
           e.preventDefault();
		   $('#popup1').bPopup({
				speed: 850,
				transition: 'slideDown', 
				modalColor: '#000000',
				opacity: 0.5
			});
     });
	 
	 $('.call-2popup').on('click', function ( e ) {
           e.preventDefault();
		   $('#popup2').bPopup({
				speed: 850,
				transition: 'slideDown', 
				modalColor: '#000000',
				opacity: 0.5
			});
     });
	 
	 
	 /**************************************
	 Captcha
	 ***************************************/
	 
	function mirror(name) {
		var name_l;
		switch (name) {  
			case 0:
				name_l = "ноль"; 
				break;
			case 1:
				name_l = "один"; 
				break;
			case 2:
				name_l = "два"; 
				break;
			case 3:
				name_l = "три"; 
				break;
			case 4:
				name_l = "четыре"; 
				break;
			case 5:
				name_l = "пять"; 
				break;
			case 6:
				name_l = "шесть"; 
				break;
			case 7:
				name_l = "семь"; 
				break;
			case 8:
				name_l = "восемь"; 
				break;
			case 9:
				name_l = "девять"; 
				break;
			case 10:
				name_l = "десять"; 
				break;
			default:
				name_l = "Error"; 
		}
		
		return name_l;
	}
	
	
	 function captcha() {
		var first = Math.floor(Math.random() * 11),
		second = Math.floor(Math.random() * 11),
		operation = Math.round(Math.random());

		 if (first == second) {
			first = Math.floor(Math.random() * 6) + 5;
			second = Math.floor(Math.random() * 6);
		}
		first_l = mirror (first); 
		second_l = mirror (second);
		
		$('.first-digit').html(first_l);
		$('.second-digit').html(second_l);

		if (operation == 1)  {
			captcha_value = first + second;
			$('.operation').html("плюс");
        }
		else
        {
			captcha_value = first - second;
			$('.operation').html("минус");
        }
	 }
	 
	 captcha();
	 
	
	/**************************************
	 Send forms
	 ***************************************/
	 
	 function validateEmail(form_id) {
		if ($("#" + form_id + " input[name=email]").length) {
			var email = $("#" + form_id + " input[name=email]").val(),
				emailPattern = /^[a-zA-Z0-9._\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,4}$/;
			if (emailPattern.test(email)) {
				$("#" + form_id + " input[name=email]").removeClass('error_input');
				return true;
			}
			$("#" + form_id + " input[name=email]").addClass('error_input');
			return false;
		}
		return true;
    }
	
	function validateName(form_id) {
		if ($("#" + form_id + " input[name=name]").length) {
			var name = $("#" + form_id + " input[name=name]").val(),
				patt =  /^[а-яА-Яa-zA-Z\s\.]{3,30}$/;
			if (patt.test(name)) {
				$("#" + form_id + " input[name=name]").removeClass('error_input');
				return true;
			}
			$("#" + form_id + " input[name=name]").addClass('error_input');
			return false;
		}
		return true;
    }
	
	function validatePhone(form_id){
		if ($("#" + form_id + " input[name=phone]").length) {
			var phone = $("#"+form_id+" input[name=phone]").val().replace(/\D/g,''),
				phoneLength = phone.length;
			if (phoneLength>4){
				$("#"+form_id+" input[name=phone]").removeClass( 'error_input' );
				return true;			
			}
			$("#"+form_id+" input[name=phone]").addClass( 'error_input' );
			return false;
		}
		return true;
	}
	
	function validateMsg(form_id) {
		if ($("#" + form_id + " textarea[name=msg]").length) {
			var msg = $("#" + form_id + " textarea[name=msg]").val();
			if (msg.length > 5) {
				$("#" + form_id + " textarea[name=msg]").removeClass('error_input');
				return true;
			}
			$("#" + form_id + " textarea[name=msg]").addClass('error_input');
			return false;
		}
		return true;
    }
	
	function validateCaptcha(form_id) {
		if ($("#" + form_id + " input[name=captcha]").length) {
			var input_captcha = $("#"+form_id+" input[name=captcha]").val();
			if (input_captcha == captcha_value) {
				$("#"+form_id+" input[name=captcha]").removeClass( 'error_input' );
				return true;			
			}
			$("#"+form_id+" input[name=captcha]").addClass( 'error_input' );
			captcha();
			return false;
		}
		return true;
	}
	
	function validateForm(form_id) {
		var a = validateName(form_id),
			b = validatePhone(form_id),
			c = validateCaptcha(form_id),
			d = validateEmail(form_id),
			e = validateMsg(form_id);
        return a && b && c && d && e;
    }

    function ajaxFormRequest(result_id, form_id, url) {
        if (validateForm(form_id)) {
            $.ajax({
                url:    url,
                type:     "POST",
                dataType: "html",
                data: $("#" + form_id).serialize(),
                beforeSend: function () {
                    $("#" + result_id).html("<span class='success'>Отправка...</span>");
                },
                success: function (response) {
                    $("#" + result_id).html(response);
                    $("#" + form_id).trigger("reset");
                },
                error: function () {
                    $("#" + result_id).html("<span class='error'>Error.</span>");
                }
            });
        } else { $("#" + result_id).html("<span class='error'>Проверьте заполнение полей!</span>"); return false; }
    }
	
	$('#callme_form').submit(function (e) {
        e.preventDefault();
        ajaxFormRequest('result1', 'callme_form', 'processform.php');
		return false;
    });
	
	$('#feedback_form').submit(function (e) {
        e.preventDefault();
        ajaxFormRequest('result2', 'feedback_form', 'processform.php');
		return false;
    });
    
});